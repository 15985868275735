.top {
  width: 100%;
  display: grid;
  grid-template-columns: 34% 24px auto;

  .columnPadding {
    width: 24px;
  }
}

.center {
  width: 100%;
  display: grid;
  grid-template-columns: 34% 24px auto;
  margin: 22px 0;

  .columnPadding {
    width: 24px;
  }
}

.centerAlternative {
  width: 100%;
  display: flex;
  margin: 22px 0;

  > section {
    width: 100%;
  }
}

.main {
  font-family: 'Rubik', sans-serif;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 700px;
  color: #636A70;
}

@media all and (max-width: 740px) {
  .main {
    min-height: 0;
    padding-top: 7px;
    display: table;

    .top {
      display: table;

      .columnPadding {
        display: none;
      }
    }

    .center {
      width: 100%;
      display: table;
      padding-top: 7px;

      .columnPadding {
        display: none;
      }
    }
  }
}
