@import "../../style/colors";

.support {
  margin: 86px auto;
  background-color: $transparent;
  box-shadow: none;
  text-align: center;
  > button {
    margin-left: 20px;
  }
}

@media all and (max-width: 750px) {
  .support {
    margin: 30px auto !important;
  }
}
