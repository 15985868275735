$primaryColor: #3cced6;

$headerActiveColor: #38d6ce;

$bgColor: #f5f7fb;

$textPrimaryColor: #636A70;

$textSecondaryColor: #808080;

$dividerColor: #efefef;
$transparent: rgba(0, 0, 0, 0);
$tableHeaderColor: #f7f7f7;
$tableBorderRow: #E0E2E8;
$tableGreen: #51CB4D;

$titlePrimary: #4BCFD0;
$subtitlePrimary: #636A70;
$hoverPrimary: #FF8554;
$white: #fff;
$borderSubscription: #dfdfdf;
$red: #E96D67;

$remitlyBg: #276ca2;
$remitlyDisabled: #1c4e75;
$remitlyRoot: #F4F6FA;
