@import "../../style/colors";

.main {
  font-family: 'Open Sans', sans-serif;
  margin: 24px auto 0;
  max-width: 600px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 15px;
  color: black;
}

.bonusContainer{
  padding: 10px;
}
.bonus {
  display: flex;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  background-color: #C7EFDC;
  padding: 16px;
  box-sizing: border-box;

  .giftBoxIcon {
    margin-right: 16px;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    background-image: url("../../img/giftRem.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.wrapperLoader {
  > div {
    background-color: $white;
  }
}

.title {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  padding: 15px 0 5px 0;
  color: black;
}

.labelRow {
  margin-bottom: -14px;
  font-size: 16px;
  font-weight: 600;
}

.subtitle {
  font-size: 12px;
  color: $textSecondaryColor;
  margin-bottom: 20px;

}

.form {
  padding: 15px 10px;
}

.footerAmount {
  padding: 15px 10px;
  max-width: 600px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.row {
  position: relative;
  width: 100%;
  margin: 14px 0;

}

.customField {
  background-color: $white;

  > div {
    > div {
      background-color: $white !important;
    }
  }
}

.birthDateButton {
  margin-top: 0;
}

.error {
  margin-top: -10px !important;
}

.submit {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background-color: #24ABE3 !important;
  min-height: 56px !important;
  margin: 10px 0 !important;
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  box-shadow: none !important;

  &:disabled {
    background-color: #24ABE3 !important;
    opacity: 0.5;
    color: $white !important;
  }

  &:hover {
    background-color: #1d8ebd !important;
  }
}

.ignoreDisableColor {
  color: black !important;
}

.select {
  > div {
    padding-left: 10px !important;
  }
}

.center {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperFullLoader {
  z-index: 100;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.giftBox {
  width: 100%;
  margin: 10px 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
  font-size: 1rem;
  line-height: 32px;
  font-weight: 400;
  background: rgba(37, 198, 218, 0.9);
  border-radius: 10px;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;

  .gift {
    width: 30px;
    height: 30px;
    background-image: url("../../img/giftbox 1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
  }
}

.flag {
  margin-right: 5px;
}

.info {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;


  > svg {
    fill: #212E60;
  }

  > div {
    margin-top: 10px;
    color: $textSecondaryColor;
    margin-left: 10px;
    font-size: 12px;


  }
}

.label {
  z-index: 100;
  background-color: $white;
}

.whiteBlock {
  background-color: $white;
  display: flex;
  justify-content: center;
  z-index: 2;
  //max-width: 100vw;
  //width: 100vw;
  min-height: 350px;
}

.priority {
  position: relative;
  z-index: 3;
}

.shadow {
  //margin-top: 20px !important;

  > div {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
    box-sizing: border-box;

  }
}

.box {
  display: block !important;
  border-bottom: 1px solid $dividerColor;
  margin-bottom: 20px;

  div:nth-child(1) {
    padding-bottom: 6px;
    font-size: 14px;
  }


  div:nth-child(2) {
    font-size: 18px;
    font-weight: bold;
    color: #87b183;
  }
}

.desc {
  margin-top: -5px;
  font-size: 14px;
  font-weight: 400;
}

.empty {
  height: 50px;
  width: 100%;
}

.infoBlock {
}


.infoTransfer {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.bold {
  font-weight: 500;
}

.wrapProgress {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 660px) {
  .main {
    padding: 0 !important;
  }
}

@media (max-width: 600px) {
  .wrap {
    padding-right: 15px !important;
    padding-left: 15px !important;
    box-sizing: border-box;
  }
}

.process {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}


