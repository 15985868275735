@import "../../style/colors.scss";

.whiteScreen {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.refreshIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -10px;
}

.bg {
  font-family: 'Rubik', sans-serif;
  margin-top: 91px;
  background-color: #4BCFD0;
  position: relative;
  width: 100%;
  background-image: url("../../img/yollahands.png");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  height: 535px;
  justify-content: flex-end;
}

.payment {
  padding: 56px 0 78px 112px;
  width: 50%;
  margin-top: 0;
  position: relative;
  color: $white;

  &:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }
}

.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
}

.subtitle {
  margin-top: 36px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.payVariantTitle {
  margin: 24px 0 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.text {
  max-width: 405px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 12px 0 36px;
}

.buttons {

}

.buttonCustom {
  color: white !important;
  border: 2px solid #FF8554 !important;
}

.back {
  padding: 3px 0 !important;
  border-radius: 0 !important;
  min-height: 0 !important;
  color: white !important;
  margin-top: 24px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  &:hover {
    background-color: transparent !important;
  }
}


@media all and (max-width: 1151px) {
  .bg {
    margin-top: 61px;
  }
}

@media all and (max-width: 1100px) {
  .payment {
    margin-left: 40px;
  }
}

@media all and (max-width: 1000px) {
  .bg {
    margin-top: 80px !important;
    background-image: none;
  }
}

@media all and (max-width: 740px) {
  .bg {
    margin-top: 80px;
  }
  .payment {
    margin-left: 0;
    padding: 30px;
    width: 100%;
  }
}
