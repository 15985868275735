@import "fonts.scss";
@import "colors.scss";

$logoWidth: 140px;

.App {
  font-family: 'Rubik', sans-serif;
  font-size: 28px;
}
html, body {
  font-weight: normal;
  padding: 0;
  font-family: Roboto, Helvetica, 'Rubik', sans-serif;
  background-color: white;
  margin: 0 auto;
}

#root, .root {
  height: 100%;
  display: block;
  background-color: white;
}

h1 {
  font-weight: 400;
  font-size: 32px;
}

strong {
  font-weight: 500;
}

.clear {
  clear: both;
}

.p{
  padding-top: 5px;
  padding-bottom: 8px;
}

.refreshIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -10px;
}

.whiteScreen{
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;left: 0;
  display: flex;

}

.main {
  font-family: 'Rubik', sans-serif;
  margin: 0 auto;
  max-width: 1005px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  min-height: 700px;
  padding-top: 30px;

  .top {
    width: 100%;
    display: table;

    .column-padding {
      display: table-cell;
      width: 20px;
    }
  }
}

/**
  transitions
*/

.slide-enter {
  max-height: 0;
  overflow: hidden;
  transition: all .2s ease-in;
}

.slide-enter.slide-enter-active {
  max-height: 200px;
}

.slide-leave {
  max-height: 200px;
  transition: all .2s ease-out;
}

.slide-leave.slide-leave-active {
  overflow: hidden;
  max-height: 0;
}

/**
mobile adaptation
*/

@media all and (max-width: 740px) {

  #intercom-container {
    display: none;
  }

  main {

    margin-top: 67px;
    min-height: 0;
    padding-top: 7px;

    .top .account{
      display: block;
      width: auto;
    }

    .top .column-padding {
      display: none;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.grecaptcha-badge { visibility: hidden; }


