@import "../../style/colors";
@import "../../style/mixins";

.invite {
  background-color: white;
  border-radius: 28px;
  overflow: hidden;
  min-width: 387px;

  .toolbar {
    @include primary-title-section;
    @include section-paddings;
  }

  .copyIcon {
    font-size: 1.3rem;
    background-size: cover;
    display: flex;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    @include content-paddings;

    p {
      @include primary-text-section;

      strong {
        @include strong-text;
        font-size: 120%;
        line-height: 38px;
      }
    }

    .shareButtons {
      margin-top: 20px;
      display: flex;

      > a {
        cursor: pointer;

        > div {
          width: 18px;
          height: 18px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .facebook {
          background-image: url("../../img/facebook.svg");
          margin-right: 18px;
        }

        .twitter {
          background-image: url("../../img/twitter.svg");
        }
      }
    }
  }
}

.flexItem {
  display: flex;
}

.copyButton {
  text-decoration: none;
}

@media all and (max-width: 740px) {
  .invite {
    display: block;
    min-width: auto;
  }
  .content {
    padding: 20px 10px;
  }

  .toolbar {
    border-radius: 0;
    padding: 11px 12px;
  }
}


@media (max-width: 600px) {
  .copy {
    display: none;
  }

  .flexItem {
    width: 100%;

    > div {
      width: 100% !important;
    }
  }

  .copyButton {
    min-width: 10px !important;
    padding: 6px 8px !important;
  }
}


