@import "../../style/colors.scss";
@import "../../style/mixins";

.maxWrap {
  width: 70%;
  text-align: center;
}

.wrapper {
  background-color: $white;
  padding: 36px 24px;
  margin-top: 24px;
  border-radius: 28px;
}

.whiteScreen {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.refreshIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -10px;
}

.bg {
  font-family: 'Rubik', sans-serif;
  margin-top: 91px;
  background-color: #4BCFD0;
  position: relative;
  width: 100%;
  min-height: 50vh;
  background-image: url("../../img/hand.png");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  height: auto;
  justify-content: flex-end;
}

.wrapperLoader {
  margin-top: 0;
  position: relative;
  width: 50%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }
}

.subscription {
  padding: 56px 0 78px 112px;
  width: 50%;
  margin-top: 0;
  position: relative;
  color: $textPrimaryColor;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.2);
  border-radius: 0px 0px 0px 200px;
  border: 2px solid rgba(255, 255, 255, 0.2);

  &:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }
}

.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
}

.flag {
  width: 28px;
  height: 28px;
  display: block;
  text-align: left;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  display: flex;
  justify-content: center;
}

.contentWrap {
  margin: 40px 0 28px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.duration {
  @include textSection;

  .counter {
    @include titleSection;
  }

  .counterRed {
    @include titleSection;
    color: $red;
  }

  > span {
    @include titleSection;
  }
}

.volume {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .amount {
    @include titleSection;
  }
}

.payVariantTitle {
  margin: 24px 0 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.text {
  @include textSection;
  margin: 20px 0 24px 0;
}

.link {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  a {
    @include primary-text-section;
    color: $titlePrimary;
    @include strong-text;

    text-decoration: none;

    &:hover {
      color: $hoverPrimary;
    }
  }
}

.button {
  > button {
    width: 100%;
  }
}

.buttonCustom {
  color: white !important;
  border: 2px solid #FF8554 !important;
}

.back {
  padding: 3px 0 !important;
  border-radius: 0 !important;
  min-height: 0 !important;
  color: white !important;
  margin-top: 24px !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  &:hover {
    background-color: transparent !important;
  }
}

.message {
  @include textSection;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  padding: 20px 18px;
  background-color: #E2FFFF;
  border-radius: 12px;

  .activate {
    background-image: url("../../img/check.svg");
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    margin-left: 10px;
  }
}


@media all and (max-width: 1151px) {
  .bg {
    margin-top: 61px;
  }
}

@media all and (max-width: 1100px) {
  .subscription {
    margin-left: 40px;
  }
}

@media all and (max-width: 1000px) {
  .bg {
    margin-top: 80px !important;
    background-image: none;
  }
}

@media all and (max-width: 740px) {
  .bg {
    margin-top: 80px;
  }
  .subscription {
    margin-left: 0;
    padding: 30px;
    width: 100%;
    border-radius: 0;
  }

  .maxWrap {
    width: 100%;
    text-align: center;
  }
}
