@import "../../../style/colors";
@import "../../../style/mixins";

.cardPaymentsInfo {
  position: relative;
  padding: 15px 50px 15px 23px;
  opacity: 1;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 28px;
  border: 2px solid $hoverPrimary;
  background: $white;
  display: flex;
  align-items: center;

  &.cardInvisible {
    opacity: 0;
    padding: 0;
    margin: 0;
    height: 1px;
    transition: padding-top 0.5s ease-in-out, padding-left 0.5s ease-in-out, padding-bottom 0.5s ease-in-out, padding-right 0.5s ease-in-out;
  }
}


.iSign {
  width: 83px;
  height: 75.3px;
  margin-right: 21px;
  background-image: url("../../../img/payment_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.cardText {
  @include primary-text-section;
  align-self: center;
  line-height: 16px;

  span {
    @include strong-text;
    margin: 0 5px 0;
    border-bottom: 3px dashed;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 23px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .iSign {
    width: 100%;
    max-width: 66px;
    height: 75px;
    background-position: top;
    margin-right: 14px;
  }

  .cardText {
    @include primary-text-section;
    text-align: justify;
    padding-right: 40px;

    > strong {
      color: $titlePrimary;
    }
  }

  .cardPaymentsInfo {
    padding: 15px 16px 26px 7px;
    margin-bottom: 24px;
    box-sizing: border-box;
    align-items: flex-start;

    &.cardInvisible {
      opacity: 0;
      padding: 0;
      margin: 0;
      height: 1px;
      transition: padding-top 0.5s ease-in-out, padding-left 0.5s ease-in-out, padding-bottom 0.5s ease-in-out, padding-right 0.5s ease-in-out;
    }
  }
}


