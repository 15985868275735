@import "./src/style/colors";
@import "./src/style/mixins";

.mainWrap {
  color: $subtitlePrimary;
  width: 100%;
  display: table;
  margin-bottom: 22px;

  .columnPadding {
    display: table-cell;
    width: 24px;
  }
}

.cardsWrap {
  width: 33%;
  min-width: 300px;
}

.autoTopupWrap {
  display: table-cell;
}

.wrapper {
  display: table-cell;
  background-color: #fff;
  overflow: hidden;
  border-radius: 28px;

  .toolbar {
    @include primary-title-section;
    @include section-paddings;
  }
}

.cards {
  @include content-paddings;
}

.titleCards {
  @include primary-subtitle-section;
  margin: 0 20px;
}

.pointer {
  cursor: pointer;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;

  .cardInfo {
    display: flex;
    align-items: center;

    .typeCard {
      width: 40px;
      height: 40px;
      background-size: contain;
      margin-left: 6px;
    }

    .numberCard {
      margin: 0 5px 0 15px;
      @include primary-subtitle-section;
    }
  }

  .controlCard {
    cursor: pointer;

    .delete {
      color: $subtitlePrimary !important;

      &:hover {
        color: $subtitlePrimary !important;
      }
    }
  }
}

.autoTopup {
  @include content-paddings;
  display: flex;
  flex-direction: column;

  .switchBox {
    display: flex;
    align-items: center;
  }

  .titleAuto {
    @include primary-subtitle-section;
  }
}

.visa {
  background: center no-repeat url("../../img/Visa.svg");
}

.masterCard {
  background: center no-repeat url("../../img/MasterCard.svg");
}

.other {
  background: center no-repeat url("../../img/card.svg");
}

.footer {
  width: 75%;
  padding: 10px 0;
  @include primary-text-section;
  line-height: 16px;
  letter-spacing: 0.01em;

  > div {
    margin-top: 12px;

    > a {
      text-decoration: none;
      color: $titlePrimary;
      @include strong-text;

      &:hover {
        color: $hoverPrimary;
      }
    }
  }
}

@media all and (max-width: 740px) {
  .wrapper {
    display: block !important;
  }
  .columnPadding {
    display: none !important;
  }
  .cardsWrap {
    width: auto;
    margin-bottom: 22px;
  }
  .autoTopupWrap {
    display: block !important;
    width: auto;
  }

  .footer {
    width: 100%;
  }

  .titleCards {
    @include primary-text-section;
  }

  .titleAuto {
    font-size: 14px !important;
  }
}
