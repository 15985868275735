@import "colors";

@mixin titleSection {
  font-size: 24px;
  font-weight: 500;
}

@mixin subTitleSection {
  font-size: 18px;
  font-weight: 500;
}

@mixin textSection {
  font-weight: 400;
  font-size: 14px;
}

@mixin primary-title-section {
  color: $titlePrimary;
  @include titleSection;
}

@mixin secondary-title-section {
  @include titleSection;
}

@mixin primary-subtitle-section {
  color: $subtitlePrimary;
  @include subTitleSection;
}

@mixin primary-text-section {
  color: $subtitlePrimary;
  @include textSection;
}

@mixin strong-text {
  font-weight: 500;
}

@mixin section-paddings {
  padding: 31px 35px 18px 35px;
  @media screen and (max-width: 740px) {
    padding: 18px 20px 12px 20px;
  }
}

@mixin content-paddings {
  padding: 0 34px 40px 34px;
  @media screen and (max-width: 740px) {
    padding: 0 20px 28px 20px;
  }
}

@mixin chose-payment {
  @media screen and (max-width: 1000px) {
    display: grid !important;
    grid-column-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
    white-space: nowrap;
    width: 100%;
    > span {
      max-width: 100% !important;
      width: 100% !important;

      > button {
        max-width: 100% !important;
        width: 100% !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

}

@mixin types-payment {
  > button {
    margin: 0 2px;
  }
  @media screen and (max-width: 740px) {
    display: grid !important;
    grid-column-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
    white-space: nowrap;
    width: 100%;
  }
}
