.deleteCode {
  margin-left: 15px;
}

.input {
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  color: #4BCFD0 !important;
  > label {
    color: #4BCFD0 !important;
  }
  > div {
    color: #636A70 !important;
  }
}

.codeTopic {
  margin-top: 10px;
  font-size: 16px;
  margin-left: 13px;
  p {
    margin: 0;
  }
}

.content {
  color: #636A70;
  a {
    color: #4BCFD0 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.aftertopic {
  font-size: 18px;
  margin-top: 30px;
  color: #636A70;
  font-weight: normal;
}

.deleteAccount {
  &Ul {
    font-size: 16px;
    color: #636A70;
  }
  &Ol {
    margin-top: 10px;
    font-size: 16px;
    color: #636A70;

    &Title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.deleteButtonsWrapper {
  display: flex;
  //justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;

  .info {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 320px;
    font-size: 10px;
  }

  .deleteButton,
  .finalDeleteButton {
    background-color: red !important;
    color: #fff !important;
    border-bottom: 1px solid #000 !important;

    &:hover {
      background-color: #ce3535 !important;
    }
  }
}

.textFieldWrapper {
  display: flex;

  .inputPrefix {
    display: flex;
    align-items: center;
    margin-right: 6px;
    margin-top: -6px;
    span {
      position: relative;
      top: 14px;
    }
  }
}

.marginTP {
  margin-top: 10px;
}
