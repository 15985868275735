@import "../../style/colors.scss";
@import "../../style/mixins";

.whiteScreen {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.refreshIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -10px;
}

.bg {
  font-family: 'Rubik', sans-serif;
  margin-top: 91px;
  background-color: #4BCFD0;
  position: relative;
  width: 100%;
  background-image: url("../../img/yollahands.png");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  height: 535px;
  justify-content: flex-end;
}

#wrapperPlus {
  margin-top: 0 !important;
}

.plus {
  color: $textSecondaryColor;
}

.label {
  @include primary-subtitle-section;
  letter-spacing: 0.01em;
  color: $white;
}

.p30 {
  margin-top: 30px;
}

.input {
  margin: 4px 0 !important;
  min-width: 250px;
  max-width: 250px;
  width: 250px;
  color: $textSecondaryColor !important;

  > label {
    color: $textSecondaryColor !important;
  }

  > div {
    color: $textSecondaryColor !important;
    height: 40px;

    > input {
      height: 40px !important;
      padding-top: 5px !important;
    }
  }
}

.signIn {
  padding: 80px 0 78px 112px;
  width: 50%;
  margin-top: 0;
  position: relative;
  color: #fff;
  z-index: 1;

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.2);
    opacity: 0.8;
    border-radius: 0 0 0 150px;
    border: 2px solid rgba(255, 255, 255, 0.1);
  }

  .titleLogin {
    font-size: 36px;
    line-height: 43px;
    font-weight: 500;
    margin-bottom: 36px;
  }

  .subtitleLogin {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
  }

  p {
    font-size: 14px;
  }

  .inputPrefix {
    float: left;
    margin-left: -15px;
    padding-top: 25px;
    display: inline-block;
  }

  .anotherNumber {
    white-space: nowrap;
    line-height: 25px;
    color: white !important;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    min-height: 10px;
    margin-top: 5px;

    &:hover {
      background-color: inherit !important;
      color: $hoverPrimary !important;
    }
  }

  .register {
    margin-top: 10px;
    font-size: 14px;
  }
}

.regeg {
  margin-left: 40px;
  color: #ffffff !important;
}

.text {
  margin: 24px 0 36px;
  font-size: 14px;
  font-weight: 400;
  max-width: 400px;

  &_small {
    font-size: 12px;
  }

  a {
    color: #ffffff;
  }
}

@media all and (max-width: 1151px) {
  .bg {
    margin-top: 91px;
  }
}

@media all and (max-width: 1100px) {
  .signIn {
    margin-left: 40px;
  }
}

@media all and (max-width: 1000px) {
  .bg {
    margin-top: 80px !important;
    background-image: none;
  }
}

@media all and (max-width: 740px) {
  .bg {
    margin-top: 80px;
  }
  .signIn {
    margin-left: 0;
    padding: 30px;
    width: 100%;
  }
}

.errorMessage {
  display: flex;
  flex-direction: column;
  margin-bottom: -15px;
  font-size: 14px;
  color: #f44336;
  line-height: 1.66;
  font-weight: 400;
  box-sizing: border-box;
  word-break: break-word;
  max-width: 300px;
}

.setErrorMessage {
  max-width: 470px;
}

.linkApp {
  font-weight: 600;
  cursor: pointer;
  z-index: 100;
  position: relative;
  text-decoration: underline;
}

.linkAppA {
  font-weight: normal;
  cursor: pointer;
  z-index: 100;
  position: relative;
  text-decoration: underline;
}
