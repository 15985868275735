@import "../../style/colors.scss";
@import "../../style/mixins";

.mobileBtn {
  display: none !important;
}

.customButtons {
  @include chose-payment;
}

.balance {
  background-color: #ffffff;
  display: table-cell;
  border-radius: 28px;
  overflow: hidden;
  color: $subtitlePrimary;

  .content {
    @include content-paddings;
  }

  .updateBalance {
    cursor: pointer;
    padding: 0;
    @include titleSection;
    margin: 5px 0 0 0;
  }

  .chooseAmount {
    @include primary-text-section;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -10px;
    margin-bottom: -10px;

    .label {
      max-width: 50%;
    }

    .types {
      @include types-payment;
    }

    .chooseAmountLabel {
      white-space: nowrap !important;
    }
  }

  .agreement {
    @include primary-text-section;
    @include strong-text;
    color: $titlePrimary;
    text-decoration: none;

    &:hover {
      color: $hoverPrimary;
    }
  }

  .toolbar {
    @include section-paddings;
    @include primary-title-section;

    a {
      @include primary-text-section;
      color: $titlePrimary;
      @include strong-text;
      float: right;
      text-decoration: none;

      &:hover {
        color: $hoverPrimary;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      font-size: 14px !important;
    }
  }

  .info {
    padding-top: 20px;
    color: grey;

    > label {
      font-size: 14px;
      font-weight: 400;
    }

    > div > div {
      width: 25px;
      height: 25px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
    }

    label {
      padding: 5px 10px 0 0;
      float: left;
    }

    .visaIcon {
      width: 75px;
      height: 24px;
      background-image: url("../../img/Visa.svg");
      margin-left: 18px;
    }

    .cardIcon {
      width: 30px;
      height: 24px;
      background-image: url("../../img/card.svg");
    }

    .mastercardIcon {
      width: 40px;
      height: 24px;
      margin-left: 18px;
      background-image: url("../../img/MasterCard.svg");
    }

    .paypalIcon {
      background-size: 80%;
      margin-left: 20px;
      background-image: url("../../img/PayPal.svg");
    }

    .paypal {
      background-size: 80%;
      background-image: url("../../img/PayPal.svg");
    }
  }
}

.flexItem {
  display: flex;
}

@media all and (max-width: 900px) {
  .types {
    white-space: nowrap !important;
  }
}

@media all and (max-width: 884px) {
  .paypalIcon {
    margin-left: 30px !important;
  }
  .label {
    max-width: 33% !important;
  }
  .chooseAmountLabel {
    display: none;
  }

}

@media all and (max-width: 740px) {

  .mobileBtn {
    display: block !important;
    width: 100%;
    margin-top: 27px !important;
  }

  .status {
    display: block !important;

    .desktopBtn {
      display: none;
    }

    > button {
      margin-top: 22px;
      width: 100%;
    }
  }
  .balance {
    margin-top: 24px;
    display: block;
    width: auto;

    .recurringChooser {
      .cvv {
        margin-right: 10px;
      }
    }

    .paypalIcon {
      margin-left: 30px !important;
    }

    .chooseAmount, .chooseMethod {
      label {
        display: none;
      }
    }

    .agreementMobile {
      display: flex;
    }

    .chooseAmount {
      text-align: center;
    }

    .methodBtn {
      display: block;
    }
  }
}

.clear {
  clear: both;
}

.defaultButton {
  width: 25px;
  height: 25px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}

.processLoader {
  display: flex;
  width: 100%;
  justify-content: center;
}
