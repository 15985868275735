$maxWidth: 1151px;


.footer {
  display: grid;
  overflow: hidden;
  background-color: white;
  font-family: 'Rubik', sans-serif;
  color: #636A70;

  .wrapperDesktop {
    background-color: white;
    max-width: 1182px;
    width: 100vw;
    margin: 0 auto;
    padding: 112px 15px 105px 15px;
    box-sizing: border-box;

    > div {
      display: flex;
    }

    .item {
      display: flex;
    }

    .subscribe {
      box-sizing: border-box;
      position: relative;
      left: 14px;
    }

    .info {
      margin-right: 186px;
    }

    .download {
      margin-left: 100px;
    }
  }

  .bottom {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 80px;
  }

  .breadcrumbs {
    font-size: 14px;
    margin-top: -10px;
    flex-grow: 1;

    .active {
      color: #4bcfd0;
    }

    > a {
      text-decoration: none;
      color: #636A70;

      &:hover {
        text-decoration: none;
      }

      cursor: pointer;
    }

    > span {
      margin: 0 10px 0 11px;

      > svg {
        width: 10px !important;
        height: 10px !important;
        font-size: 10px !important;
      }

    }
  }

  .lang {
    padding-left: 146px;
    font-size: 14px;
    position: relative;

    > div {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;

      > a {
        text-decoration: none;
        color: #636A70;

        &:hover {
          color: #FF8554;
        }
      }
    }
  }

  .social {
    padding-left: 450px;
    display: flex;
    margin-top: -10px;

    .icon {
      width: 18px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .facebook {
      background-image: url("../../../img/facebook.svg");
      margin-right: 25px;
    }

    .instagram {
      background-image: url("../../../img/instagram.svg");
      margin-right: 25px;
    }

    .twitter {
      background-image: url("../../../img/twitter.svg");
      margin-right: 25px;
    }

    .youtube {
      background-image: url("../../../img/youtube.svg");
    }
  }

  .footerCopyright {
    margin-top: 75px;
    color: #DADADA;
    font-size: 14px;
    line-height: 1.15;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .copyright {
      margin-top: -10px;
    }

    .tosLinks {
      //max-width: 220px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      > a {
        text-align: right;
        text-decoration: none;
        color: #DADADA;

        &:hover {
          color: #FF8554;
        }
      }

      a:first-child {
        padding-right: 42px;
        white-space: nowrap;
      }

    }
  }

  .wrapperMobile {
    display: none;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 21px;
  }

  .store {
    .market {
      display: flex;
      width: 105px;
      height: 34px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .googleplay {
      background-image: url("../../../img/google-play2x.png");
    }

    .appstore {
      background-image: url("../../../img/appstore2x.png");
      margin-bottom: 10px;
    }
  }
}

.ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0;
    font-size: 14px;
    line-height: 17px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.stub {
  margin-top: 12px;
  font-size: 12px;

  a {
    font-weight: 500;
  }
}

.input {
  > div {
    height: 36px !important;
    width: 193px;
    border-radius: 12px 0 0 12px !important;
    border: none !important;
    font-size: 10px !important;
    >input {
      padding-top: 0;
      padding-bottom: 0;
    }
    >fieldset {
      border-width: 1px !important;
      border-right-width: 0 !important;
    }
  }
}

.wrapInput {
  position: relative;
  display: flex;
}

.arrow {
  padding: 0;
  border: none;
  font: inherit;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 36px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 5px;
  background-color: #FF8554;
  color: white;
  cursor: pointer;
  border-radius: 0 12px 12px 0 !important;

  svg {
    font-size: 1.1rem;
  }
}

.wrap {
  display: flex;
}


.qrcode {
  margin-left: 16px;

  img {
    width: 77px;
    height: 77px;
  }
}

.dropdownMenu {
  position: absolute;
  transition: all 0.3s ease-in-out;
  padding: 0;
  width: fit-content;
  display: flex;
  top: 28px;
  background: #fff;
  border-radius: 5px;

  > li {
    box-sizing: border-box;
    padding-right: 4px;
    margin: 0 !important;

    > a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.langDrop {
  padding-top: 12px;

  > li {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }

  li:first-child {
    padding-left: 0 !important;
  }
}

.iconLang {
  font-size: 1.3rem;
  margin-left: 5px;
}

.spaceAround {
  justify-content: space-between;
}

.a {
  text-decoration: none;
  color: #636A70;

  &:hover {
    text-decoration: none;
    color: #FF8554;
  }

  cursor: pointer;
}

@media (max-width: 1024px) {
  .info {
    margin-right: 100px !important;
  }
  .download {
    margin-left: 50px !important;
  }
  .social {
    padding-left: 300px !important;
  }
  .langDrop {
    right: 0 !important;
  }
}

@media (max-width: 720px) {
  .wrapperDesktop {
    padding: 56px 10px 70px 10px !important;
  }
  .hide {
    display: none !important;
  }
  .spaceAround {
    justify-content: center;
  }
  .lang {
    padding-left: 0 !important;
  }
  .langDrop {
    left: -80% !important;
  }
  .bottom {
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
  }
  .social {
    padding-left: 0 !important;
  }

  .copyright {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .lowItem {
    display: flex !important;
    flex-flow: column-reverse;
    align-items: center;
  }

  .qrcode {
    display: none !important;
  }
  .subscribe {
    margin-top: 57px;
  }
  .lang {
    margin-bottom: 40px;
  }
  .wrapperDesktop {
    padding: 30px 0 !important;
  }
  .download {
    margin-left: 0 !important;
  }
  .subscribe {
    margin-top: 30px;
    left: 0 !important;
  }
  .wrap {
    display: flex !important;
    justify-content: center !important;
  }
  .store {
    display: flex !important;
    justify-content: center !important;

    > a {
      margin: 0 5px !important;
    }
  }
  .wrapInput {
    justify-content: center !important;
    margin-left: -5px !important;
  }
  .arrow {
    right: 5px !important;
  }
  .bottom {
    margin-top: 30px !important;
    display: flex !important;
    flex-flow: column-reverse;
    align-items: center;
  }
  .breadcrumbs {
    margin-top: 50px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      transform: rotate(90deg);
    }
  }
  .langDrop {
    padding-top: 5px !important;
  }
  .footerCopyright {
    margin-top: 30px !important;
    display: flex;
    flex-flow: column;
    align-items: center;

    .tosLinks {
      font-size: 12px;
      margin-top: 30px;
      display: flex !important;
      flex-flow: column !important;
      align-items: center;

      > a {
        margin: 10px 0 !important;
        display: block;
      }

      a:first-child {
        padding-right: 0 !important;
      }
    }
  }
}

.form {
  height: 40px;
}



