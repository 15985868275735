@import '../style/colors';

$maxWidth: 1151px;

.main {
  position: relative;
  margin: 0 auto;
  max-width: $maxWidth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 91px;
  min-height: 700px;
  padding-top: 71px;
}

.mainFix {
  min-height: 100vh;
  height: 100%;
  margin-top: 0;
  background-color: $remitlyRoot;
  justify-content: flex-start;
  padding-top: 0;
  max-width: 100vw;
  overflow: hidden;
}

.mainLogin {
  max-width: 100vw;
  margin-top: 0;
  min-height: 500px;
  background-color: #fff;
  padding-top: 0;
}

.mainPay {
  max-width: 100vw;
  min-height: 500px;
  background-color: #e2ffff;
  padding-top: 0;
  margin-top: 91px;
}

.root {
  height: 100%;
  display: block;
  background-color: #e2ffff;
  position: relative;
}

@media (max-width: $maxWidth) {
  .main {
    padding: 0 42px;
    margin-top: 80px;
    padding-top: 30px;
    box-sizing: border-box;
  }
  .mainFix {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .mainLogin {
    padding: 0;
    margin-top: 0;
  }
}

@media (max-width: 1000px) {
  .main {
    padding: 36px 16px;
  }
  .mainLogin {
    padding: 0 !important;
  }
  .mainFix {
    padding-bottom: 0 !important;
  }
}



@media (max-width: 660px) {
  .mainFix {
    padding: 0 !important;
  }
}
