@import "./src/style/colors";
@import "./src/style/mixins";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.toolbar {
  @include section-paddings;
  @include primary-title-section;
}

.history {
  background-color: $white;
  border-radius: 28px;
  overflow: hidden;
  .content {
    @include content-paddings;
  }

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;

    .secondary {
      color: $textSecondaryColor;
    }

    th {
      @include subTitleSection;
      text-align: left;
      padding: 13px 10px 13px 20px;
      color: $titlePrimary;
    }

    th:first-child {
      padding-left: 0;
    }

    td {
      @include primary-text-section;
      padding: 27px 10px 23px 20px;
      border-bottom: 1px solid $tableBorderRow;
    }

    td:first-child {
      padding-left: 0;
    }

    tfoot {
      tr {
        td {
          border-bottom: 0 solid $tableBorderRow;
        }
      }
    }

    tr {
      background: $white;
    }

    .flag {
      width: 22px;
      margin: -2px 10px 0 0;
      float: left;
    }

    tbody {
      tr:last-child {
        td {
          border-bottom: 0 solid $tableBorderRow;
        }
      }
    }

    tfoot td {
      padding-bottom: 20px;
      padding-top: 20px;
      color: $textSecondaryColor;
      height: 40px;
    }

    .topup td {

      a {
        @include strong-text;
        color: $titlePrimary;
        text-decoration: underline;

        &:hover {
          color: $hoverPrimary;
        }
      }
    }

    .topup {
      td:last-child {
        color: $tableGreen;
      }
    }

    .loading label {
      position: absolute;
      margin-left: 24px;
      font-size: 16px;
      margin-top: 5px;
    }
  }

  .showMore {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $hoverPrimary;
    padding: 0 !important;
  }
}

@media all and (max-width: 740px) {
  .history {
    table {
      thead {
        display: none;
      }

      .flag {
        margin: 0;
      }

      td {
        padding: 13px 5px 13px 6px;
      }

      .rate {
        display: none;
      }

      tbody td span {
        display: none;
      }

    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: 500 !important;
}

.call {
  display: flex;
  align-items: center;
  > svg {
    margin-right: 5px;
  }
}
