@import "../../style/colors.scss";
@import "../../style/mixins";

.flagContainer {
  display: flex;
  align-items: center;

  .flag {
    width: 28px;
    height: 28px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin-right: 8px;

    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.subscription {
  background-color: #ffffff;
  display: table-cell;
  border-radius: 28px;
  overflow: hidden;
  color: $subtitlePrimary;


  .wrapperLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .content {
    @include content-paddings;

    .data {
      display: flex;
      margin-bottom: 25px;
      align-items: center;
      justify-content: space-between;
    }

    .duration {
      padding-left: 15px;
      @include textSection;

      .counter {
        @include titleSection;
      }

      .counterRed {
        @include titleSection;
        color: $red;
      }

      > span {
        @include titleSection;
      }
    }

    .volume {
      padding-left: 15px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .amount {
        @include titleSection;
      }

      .text {
        text-align: right;
      }
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
    border-bottom: 1px solid $borderSubscription;
    border-top: 1px solid $borderSubscription;

    .activate {
      background-image: url("../../img/check.svg");
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  .textContent {
    @include textSection;
    margin: 20px 0 36px 0;
  }

  .textContentSub {
    @include textSection;
    margin-top: 20px;
  }

  .toolbar {
    @include section-paddings;
    @include primary-title-section;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      @include primary-text-section;
      color: $titlePrimary;
      @include strong-text;
      float: right;
      text-decoration: none;

      &:hover {
        color: $hoverPrimary;
        margin-left: 5px;
      }
    }
  }

  .info {
    display: flex;
    padding-top: 20px;
    color: grey;

    > label {
      font-size: 14px;
      font-weight: 400;
    }

    > div > div {
      width: 25px;
      height: 25px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
    }

    label {
      padding: 5px 10px 0 0;
      float: left;
    }

    .visaIcon {
      width: 75px;
      height: 24px;
      background-image: url("../../img/Visa.svg");
      margin-left: 18px;
    }

    .cardIcon {
      width: 30px;
      height: 24px;
      background-image: url("../../img/card.svg");
    }

    .mastercardIcon {
      width: 40px;
      height: 24px;
      margin-left: 18px;
      background-image: url("../../img/MasterCard.svg");
    }

    .paypalIcon {
      background-size: 80%;
      margin-left: 20px;
      background-image: url("../../img/PayPal.svg");
    }

    .paypal {
      background-size: 80%;
      background-image: url("../../img/PayPal.svg");
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }

  .footerActive {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
  }
}

.mobileBtn {
  > span {
    text-transform: none !important;
  }
}

.mobileLink {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  .mobileFooter {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 20px;
    }

    > a {
      padding-top: 10px;
    }
  }

  a {
    @include primary-text-section;
    color: $titlePrimary;
    @include strong-text;
    float: right;
    text-decoration: none;

    &:hover {
      color: $hoverPrimary;
    }
  }
}

@media all and (max-width: 740px) {
  .subscription {
    margin-top: 24px;
    display: block;
    width: auto;
  }

  .duration {
    display: flex;
    flex-direction: column;
  }

  .counter {
    @include textSection;
  }
}
