@import "./src/style/colors.scss";
@import "../../style/mixins";

.account {
  background-color: white;
  border-radius: 28px;
  overflow: hidden;
  min-width: 387px;

  .avatar {
    width: 72px;
    height: 72px;
    float: left;
    border-radius: 70px;
    margin-right: 20px;
    background-image: url("../../img/user_empty.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .toolbar {
    @include primary-title-section;
    @include section-paddings;
  }

  .content {
    @include content-paddings;
    display: flex;
  }
  .userFrame {
    position: relative;
    background-image: url("../../img/userbg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 90px;
    height: 90px;
    margin-right: 18px;
    >div {
      position: absolute;
      top: 13px;
      left: 4px;
    }
  }
  .info {
    @include primary-text-section;
    >div {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

  }
}

.arrowWrapper {
  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: #808080 !important;
  }
}

.bottom {
  border-top: 1px solid $dividerColor;
  padding: 25px 0 2px 0;
  clear: both;
  margin-top: 20px;
}

@media all and (max-width: 740px) {
  .account {
    display: block;
    min-width: auto;
  }
  .content {
    padding: 20px 10px;
  }

  .toolbar {
    border-radius: 0;
    padding: 11px 12px;
  }
}
