@import "../../../style/colors";

.InstallDialog {

  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;


  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    background-color: #3cced6;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .content {
    z-index: 999;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    width: 500px;
    background-position: right bottom;
    background-size: 330px;
    background-repeat: no-repeat;

    .closeIcon {
      width: 22px;
      height: 22px;
      margin-top: 24px;
      margin-right: 24px;
      background-size: 100%;
      float: right;
      cursor: pointer;
      color: #DADADA;
    }

    h1 {
      font-size: 36px;
      font-weight: 500;
      color: #4BCFD0;
    }

    p {
      font-size: 17px;
      color: $textPrimaryColor;
      font-weight: 300;
    }

    .details {
      word-break: break-word;
      padding: 20px 50px 20px 50px;

      .controls {
        padding-top: 20px;
        display: flex;
        justify-content: flex-start;

        .buttons {
          margin-right: 30px;

          img {
            width: 135px;
            margin-bottom: 5px;
          }
        }

        .qrCode {
          width: 90px;
          height: 90px;
          background-image: url("../../../img/qr.png");
          background-size: 100%;
        }
      }

    }
  }

  h1 {
    color: red;
  }
}

@media all and (max-width: 1000px) {
  .InstallDialog {
    .content {
      background-image: none;
      text-align: center;
    }
    .controls {
      justify-content: center !important;
    }
  }
}
