.PayInfo {
  margin: 0 auto;
  max-width: 1151px;
  font-family: 'Rubik', sans-serif;
  background-color: #f5f7fb;
  height: 100%;
  padding: 30px 0;

  h1 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    color: #4BCFD0;
  }

  main {
    padding: 20px;
  }

  .banner {
    background-color: #3cced6;
    display: grid;
    grid-template-areas: 'img text'
                          'img button';
    grid-template-columns: 50px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 30px 50px;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    .img {
      grid-area: img;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 50px;
      float: left;
    }
  }

  .addCredit {
    margin: 5px 0 0 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #545454;
  }
}
