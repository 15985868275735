@import "../../../style/colors.scss";

$maxWidth: 1182px;

.header {
  padding: 20px 15px;
  padding-bottom: 21px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 18px;
  z-index: 50;
  width: 100vw;
  font-family: 'Rubik', sans-serif;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 100px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(30.6091px);
  }

  .wrapper {
    z-index: 1;
    width: $maxWidth;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrapperLogo {
    z-index: 1;
  }

  .logo {
    background: url('../../../img/logo.svg') no-repeat center;
    background-size: contain;
    width: 129px;
    height: 50px;
    display: block;
    float: left;
  }

  .wrapperNavbar {
    display: flex;
    align-items: center;
  }

  .logout {
    float: right;
    margin-right: 55px;
  }

  .accountSettings {
    margin-left: 15px;
    position: relative;

    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      background-size: cover;
      margin-right: 12px;
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .menuButton {
      line-height: 30px;
      font-size: 14px;
      margin-right: 6px;
      font-weight: 500;
      text-transform: capitalize;
      color: #626a71;
      cursor: pointer;
    }
  }

  .burger {
    cursor: pointer;
    background: transparent;
    height: 29px;
    width: 40px;
    border: 5px solid #626a71;
    border-left: none;
    border-right: none;
    padding: 0;
    margin: 6px 30px 6px 0;
    display: none;
    float: right;

    &:before {
      content: '';
      display: block;
      border-top: 5px solid #626a71;
    }
  }

  .topmenu {
    float: left;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      position: relative;

      &.__active {
        a {
          border-radius: 5px;
          background: $headerActiveColor;
          color: #fff;

          &:hover {
            text-decoration: none;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 7px solid $headerActiveColor;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          margin: 0 auto;
        }
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: #626a71;
      padding: 0 6px;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .dropdownItem {
      padding: 0 10px;

      > a {
        &:hover {
          text-decoration: none;
          color: #FF8554;
        }
      }

      > div {
        > a {
          &:hover {
            text-decoration: none;
            color: #FF8554;
          }
        }
      }


      &.earn {
        padding-left: 8px;
        position: relative !important;
      }

      &.help {
        padding-left: 8px;
      }

      &.blog {
        padding-left: 10px;
      }
    }

    .dropdownItem + .dropdownItem {
      margin-left: 15px;
    }

    .dropdownMenu {
      position: absolute;
      top: 27px;
      padding: 0;
      width: fit-content;
      background-color: #4bcfd0;
      border-radius: 5px;
      box-sizing: border-box;

      &.wide {

        > li {
          display: flex;
          width: 100%;
          white-space: nowrap;
          align-items: center;
          min-height: 30px;
          font-size: 14px;
          font-weight: 500;
          color: #636A70;
          box-sizing: border-box;

          > a {
            text-decoration: none;
            color: #636A70;

            &:hover {
              text-decoration: none;
              color: #FF8554;
            }
          }
        }
      }

      &.account {
        right: 0;
        margin: 0;
      }

      li {
        display: block;
        padding: 0 6px;
      }
    }
  }

  .logoutBtn {
    float: right;
    color: #fff;
    padding: 10px 20px;
    box-shadow: 0 1px 0 0 #333940;
    border-radius: 5px;
    margin-right: 20px;
  }

  .logoutBtn,
  .btnGray:visited,
  .btnGray:active,
  .btnGray:focus {
    background: #707980;
    color: #fff;
  }
}

@media (max-width: $maxWidth) {
  .header {
    .wrapper {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .wrapperAccount {
    margin-right: 35px !important;
  }
}

@media (max-width: 1000px) {
  .menuButton {
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0;

    .logout {
      margin-right: 10px;
    }

    .topmenu {
      display: none;
      float: right;
      clear: both;
      padding-bottom: 40px;
      margin: 30px 30px 0 0;

      li {
        text-align: right;
        display: block;

        a {
          font-size: 35px;
          line-height: 65px;
        }

        &.__active {
          a {
            background: none;
            color: $headerActiveColor;
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .logo {
      transform: scale(0.75);
      transform-origin: 0 30%;
      margin-left: 20px;
      margin-right: 30px;
    }

    .burger {
      height: 20px;
      width: 28px;
      border-width: 3px 0;
      display: block;
    }

    .burger:before {
      content: "";
      display: block;
      border-top: 3px solid #626a71;
    }
  }
}

.deleteButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  .deleteButton,
  .finalDeleteButton {
    background-color: red !important;
    color: #fff !important;
    border-bottom: 1px solid #000 !important;

    &:hover {
      background-color: #ce3535 !important;
    }
  }
}

.deleteAccountList {
  li {
    margin-top: 10px;
  }
}

.textFieldWrapper {
  display: flex;

  .inputPrefix {
    display: flex;
    align-items: center;
    margin-right: 6px;
    // padding-top: 40px
    span {
      position: relative;
      top: 14px;
    }
  }
}

.deleteAccountModal {
  .deleteCode {
    margin-left: 15px;
  }

  .codeTopic {
    margin-top: 10px;
    font-size: 16px;
    margin-left: 13px;
  }

  .aftertopic {
    font-size: 17px;
    margin-top: 10px;
    margin-left: 13px;
  }

  .deleteAccountTopicWrapper {
    padding-bottom: 10px !important;
  }

  .deleteAccountList {
    padding-left: 20px;

    li {
      font-size: 15px;
    }
  }
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.iconSize {
  font-size: 1.1rem;
  margin-top: 5px;
}

.iconLang {
  font-size: 1.3rem;
}

.wrapperAccount {
  display: flex;
  align-items: center;
}

.menuItem {
  min-height: 48px !important;
  line-height: 30px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #636A70 !important;
}

.lang {
  text-transform: uppercase !important;
  position: relative;
  height: 100%;
  margin-left: 57px !important;

  > div {
    > a {
      font-weight: 100 !important;
      text-transform: uppercase !important;
      position: relative;
    }
  }
}

.wrapperLang {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.wrapperEarn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
}

.fake {
  width: 100%;
  height: 50px;
}

.fakeEarn {
  width: 100%;
  height: 50px;
}

.earnWrap {
  border-radius: 12px !important;
  margin-top: 5px;
}

.languages {
  border-radius: 12px !important;
  margin-top: 10px;

  > li {
    > a {
      font-weight: 100 !important;
      text-transform: uppercase !important;
    }
  }
}

.caret {
  width: 12px !important;
  height: 7px !important;
  background-image: url("../../../img/arrow_down.svg");
}

@media (max-width: 740px) {
  .header {
    background-color: $white;
    position: fixed;
  }
}
